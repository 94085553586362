import { TabulatorFull as Tabulator } from "tabulator-tables"; // see https://tabulator.info/docs/5.5/modules > filter, AjaxModule
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_semanticui.css";
window.DateTime = DateTime;
const currentPage = window.location.pathname;

if (currentPage === "/about/") {
    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const currentMonthIndex = currentDate2.getMonth();

    function currentMonthName(int) {
        if (currentMonthIndex - int < 0) {
            return monthNames[currentMonthIndex - int + 12];
        } else {
            return monthNames[currentMonthIndex - int];
        }
    }

    function currentYear(int) {
        if (currentMonthIndex - int < 0) {
            return (year2 % 100)- 1;
        } else {
            return (year2 % 100)
        }
    }

    const skeletonFormatter = function (cell, formatterParams) {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };

    const google_data = [
        {
            domain: "sellusedproducts.com",
            google_keywords: "sell used products",
            google_monthly_searches: 880,
            google_monthly_searches_1monthago: 720,
            google_monthly_searches_2monthago: 720,
            google_monthly_searches_3monthago: 880,
            google_monthly_searches_4monthago: 880,
            google_monthly_searches_5monthago: 880,
            google_monthly_searches_6monthago: 880,
            google_monthly_searches_7monthago: 880,
            google_monthly_searches_8monthago: 880,
            google_monthly_searches_9monthago: 1000,
            google_monthly_searches_10monthago: 880,
            google_monthly_searches_11monthago: 880,
            google_monthly_searches_12monthago: 1000,
            google_competition: 2,
            google_competition_index: 21,
            google_low_range_page_bids: (209244259 / 1000000000).toFixed(2),
            google_top_range_page_bids: (2355799979 / 1000000000).toFixed(2),
        },
        {
            domain: "domainbulksearch.com",
            google_keywords: "domain bulk search",
            google_monthly_searches: 4400,
            google_monthly_searches_1monthago: 4400,
            google_monthly_searches_2monthago: 5400,
            google_monthly_searches_3monthago: 5400,
            google_monthly_searches_4monthago: 5400,
            google_monthly_searches_5monthago: 4400,
            google_monthly_searches_6monthago: 4400,
            google_monthly_searches_7monthago: 4400,
            google_monthly_searches_8monthago: 4400,
            google_monthly_searches_9monthago: 4400,
            google_monthly_searches_10monthago: 5400,
            google_monthly_searches_11monthago: 5400,
            google_monthly_searches_12monthago: 5400,
            google_competition: 2,
            google_competition_index: 30,
            google_low_range_page_bids: (388373999 / 1000000000).toFixed(2),
            google_top_range_page_bids: (2815711498 / 1000000000).toFixed(2),
        },
        {
            domain: "applenews.com",
            google_keywords: "apple news",
            google_monthly_searches: 201000,
            google_monthly_searches_1monthago: 165000,
            google_monthly_searches_2monthago: 165000,
            google_monthly_searches_3monthago: 165000,
            google_monthly_searches_4monthago: 201000,
            google_monthly_searches_5monthago: 246000,
            google_monthly_searches_6monthago: 165000,
            google_monthly_searches_7monthago: 201000,
            google_monthly_searches_8monthago: 301000,
            google_monthly_searches_9monthago: 246000,
            google_monthly_searches_10monthago: 165000,
            google_monthly_searches_11monthago: 135000,
            google_monthly_searches_12monthago: 201000,
            google_competition: 2,
            google_competition_index: 3,
            google_low_range_page_bids: (835004099 / 1000000000).toFixed(2),
            google_top_range_page_bids: (2912804998 / 1000000000).toFixed(2),
        },
    ];
    
    const formatHeaderTooltip = function (content) {
        let content_test = content;
        var el = document.createElement("div");
        el.style.cssText = "background: #000 !important; color: #fff !important;padding:0.5rem 1rem !important; border-radius: 5px !important;";
        el.innerHTML = content_test;
        return el;
    };

    let columnTooltipAvgSearches = `Average Monthly Searches shows the average number of searches for this keyword and its close variations, worldwide in every language. This information can help you see how popular your keywords are at different times of the year.`
    let columnTooltipLowRange = `"Top of Page (Low Range) Bid" shows the lower range of what advertisers have paid in the past as a keyword's top of page bid. This metric is based on your location and search network settings. The average CPC of your keywords can vary.`
    let columnTooltipTopRange = `"Top of Page (High Reach) Bid" shows the higher range of what advertisers have paid in the past as a keyword's top of page bid. This metric is based on your location and search network settings. The average CPC of your keywords can vary.`

    const table_google = new Tabulator("#google-example-table", {
        rowFormatter: function (row) {
            const data = row.getData();
            if (data.status == 0) {
                row.getElement().style.color = "#999";
            }
        },
        data: google_data,
        columns: [
            {
                formatter: "rowSelection",
                titleFormatter: "rowSelection",
                hozAlign: "center",
                headerSort: false,
                width: 80,
                titleFormatterParams: {
                    rowRange: "active",
                },
                cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                },
            },
            { title: "DOMAIN", field: "domain", width: 150, maxWidth: 350 },
            { title: "KEYWORDS", field: "google_keywords", maxWidth: 200 },
            { title: `AVG MONTHLY SEARCHES`, field: "google_monthly_searches", headerTooltip: formatHeaderTooltip(columnTooltipAvgSearches), maxWidth: 200 },
            { title: `AVG ${currentMonthName(1)} ${currentYear(1)}`, field: "google_monthly_searches_1monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(2)} ${currentYear(2)}`, field: "google_monthly_searches_2monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(3)} ${currentYear(3)}`, field: "google_monthly_searches_3monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(4)} ${currentYear(4)}`, field: "google_monthly_searches_4monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(5)} ${currentYear(5)}`, field: "google_monthly_searches_5monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(6)} ${currentYear(6)}`, field: "google_monthly_searches_6monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(7)} ${currentYear(7)}`, field: "google_monthly_searches_7monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(8)} ${currentYear(8)}`, field: "google_monthly_searches_8monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(9)} ${currentYear(9)}`, field: "google_monthly_searches_9monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(10)} ${currentYear(10)}`, field: "google_monthly_searches_10monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(11)} ${currentYear(11)}`, field: "google_monthly_searches_11monthago", maxWidth: 200 },
            { title: `AVG ${currentMonthName(12)} ${currentYear(12)}`, field: "google_monthly_searches_12monthago", maxWidth: 200 },
            { title: "COMPETITION", field: "google_competition", maxWidth: 200 },
            { title: "COMPETITION INDEX", field: "google_competition_index", maxWidth: 200 },
            { title: "LOW RANGE PAGE BIDS", field: "google_low_range_page_bids", formatter: "money", formatterParams:{"symbol": "$"}, headerTooltip: formatHeaderTooltip(columnTooltipLowRange),  maxWidth: 200 },
            { title: "HIGH RANGE PAGE BIDS", field: "google_top_range_page_bids", formatter: "money", formatterParams:{"symbol": "$"}, headerTooltip: formatHeaderTooltip(columnTooltipTopRange), maxWidth: 200 },
        ],
        layout: "fitDataFill",
        resizableColumnFit: true,
    });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const currentDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const exportFileName = `DomainBulkSearch.com - Keyword export - ${currentDateString}`;

    //trigger download of data.csv file
    document.getElementById("download-google-csv").addEventListener("click", function () {
        table_google.download("csv", `${exportFileName}.csv`, {}, "active");
    });

    //trigger download of data.json file
    document.getElementById("download-google-json").addEventListener("click", function () {
        table_google.download("json", `${exportFileName}.json`, {}, "active");
    });

    //trigger download of data.xlsx file
    document.getElementById("download-google-xlsx").addEventListener("click", function () {
        table_google.download("xlsx", `${exportFileName}.xlsx`, { sheetName: "Keyword" }, "active");
    });

    //trigger download of data.pdf file
    document.getElementById("download-google-pdf").addEventListener("click", function () {
        table_google.download(
            "pdf",
            `${exportFileName}.pdf`,
            {
                orientation: "portrait", //set page orientation to portrait
                title: "DomainBulkSearch.com - Keyword export", //add title to report
            },
            "active"
        );
    });

    //trigger download of data.html file
    document.getElementById("download-google-html").addEventListener("click", function () {
        table_google.download("html", `${exportFileName}.html`, { style: true }, "active");
    });
}
