import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_semanticui.css";
window.DateTime = DateTime;
const currentPage = window.location.pathname;

if (currentPage === "/domains/all/" && myDjangoVariable !== "None") {
    // Filter settings
    function updateFilters(fieldName, operator, fieldToFilter) {
        fieldToFilter.forEach(function (filterOption) {
            filterOption.addEventListener("change", function () {
                let filterValues = Array.from(fieldToFilter)
                    .filter((filterVal) => filterVal.checked)
                    .map((filterVal) => filterVal.value);
                if (filterValues) {
                    deleteFilter(fieldName);
                    const filterValuesFlat = filterValues.join(" ");
                    console.log(`filter '${fieldName}' with ${operator} ${filterValues}`);
                    table.addFilter(fieldName, operator, filterValuesFlat);
                }
            });
        });
    }

    function updateFilterResetButtons() {
        let filterResetButtons = document.querySelectorAll("[filter-reset]");
        filterResetButtons.forEach(function (resetButton) {
            resetButton.addEventListener("click", function () {
                const parentElement = this.closest("li").parentElement;
                const closestInput = parentElement.querySelector("input[name]");
                const inputNameGroup = closestInput.getAttribute("name");
                const sameInputGroup = document.querySelectorAll(`input[name='${inputNameGroup}']`);
                sameInputGroup.forEach(function (input) {
                    input.checked = false;
                });
                const fieldName = resetButton.getAttribute("filter-reset");
                if (closestInput) {
                    deleteFilter(fieldName);
                } else {
                    console.log(`Not able to reset filter ${fieldName}.`);
                }
            });
        });
    }

    function deleteFilter(fieldName) {
        const filters = table.getFilters();
        const filterExists = filters.find((dict) => dict["field"] === fieldName);
        if (filterExists) {
            console.log(`Reset filter ${fieldName}`);
            table.removeFilter(filterExists.field, filterExists.type, filterExists.value);
        }
    }

    // Reset filters
    updateFilterResetButtons();

    document.getElementById("filter-extension-dropdown-btn").addEventListener("click", function () {
        const ExtValues = table.getColumn("extension");
        const result = ExtValues.getCells();
        const uniques = [];
        const filterExtensionsDOM = document.getElementById("filter-extensions");
        if (filterExtensionsDOM.innerHTML.trim() == "") {
            filterExtensionsDOM.innerHTML = "";
            result.forEach((item) => {
                const value = item.getValue();
                if (!uniques.some((dict) => dict["ext"] === value)) {
                    uniques.push({ ext: value, count: 0 });
                } else {
                    const updateThis = uniques.find((dict) => dict["ext"] === value);
                    updateThis.count += 1;
                }
            });

            // Add reset button at top of ul
            const li = document.createElement("li");
            li.innerHTML = `<div class="flex items-center justify-center text-center rounded border-blue-200 border hover:border-blue-400 dark:hover:bg-gray-600 mb-2">
            <span filter-reset="extension" class="text-sm cursor-pointer block w-full h-full px-2 py-1 font-medium text-blue-600 dark:text-blue-500">Reset</span>
            </div>`;
            filterExtensionsDOM.appendChild(li);

            // Sort from highest to lowest
            uniques.sort((a, b) => b.count - a.count);
            // Create li for each item and append below reset button
            uniques.forEach((element) => {
                const li = document.createElement("li");
                li.innerHTML = `<div class="flex items-center p-2 gap-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input type="checkbox" name="filter-extensions" value="${element.ext}" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <span class='font-bold'>${element.ext}</span> <span>(${element.count})</span>
                </div>`;

                filterExtensionsDOM.appendChild(li);
            });
        }

        // After data is loaded, continue the filtering process
        const filterExtensionsButtons = document.querySelectorAll("input[name='filter-extensions']");

        updateFilters("extension", "keywords", filterExtensionsButtons);
        updateFilterResetButtons();
    });

    // Table settings
    const formatterDomainName = function (cell, formatterParams) {
        const row = cell.getRow(cell);
        const note_value = row.getData().note;
        const value = cell.getValue();

        let result = "<span class='font-medium'><a class='hover:underline' href='http://www." + value + "' title='Visit " + value + "' target='_blank'>" + value + "</a></span>";
        return result;
    };

    const formatterSavedOn = function (cell, formatterParams) {
        let result;
        if (userIsAuthenticated !== "false") {
            console.log("User is logged in.");
            result = { formatter: "datetime", formatterParams: { inputFormat: "iso", outputFormat: "MMM dd, yyyy HH:mm:ss", invalidPlaceholder: "(invalid date)" } };
        } else {
            console.log("Not logged in.");
            result = `Login to save`;
        }
        return result;
    };

    const formatterRegisterBtn = function (cell, formatterParams) {
        var row = cell.getRow(cell);
        var domainName = row.getData().domain;
        let result = `<div class="inline-block border rounded border-1 border-blue-500 text-blue-600 hover:text-white bg-blue-50 hover:bg-blue-500 hover:border-blue-700 hover:border-b-2 hover:-mb-px">
                            <a href="https://www.godaddy.com/es/domainsearch/find?checkAvail=1&tmskey=1dom_03_godaddyb&domainToCheck=${domainName}" class="flex flex-row gap-2 items-center flex-grow px-2 py-0.5" target="_blank">
                                register
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                              </svg>
                            </a>
                        </div>`;
        return result;
    };

    const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    const skeletonFormatter = function () {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };

    const table = new Tabulator("#all-domains-table", {
        rowFormatter: function (row) {
            const data = row.getData();
            if (data.status == 0) {
                row.getElement().style.color = "#999";
            }
        },
        data: data,
        index: "domain",
        placeholder: `<div style="">No data found</div>`,
        // initialSort: [{ column: "date", dir: "desc" }], // Doesn't work at the moment with filtering
        columns: [
            {
                formatter: "rowSelection",
                titleFormatter: "rowSelection",
                hozAlign: "center",
                headerSort: false,
                width: 80,
                titleFormatterParams: {
                    rowRange: "active",
                },
                cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                },
            },
            { title: "DOMAIN", field: "domain", width: 300, formatter: skeletonFormatter, minWidth: 200 },
            { title: "EXTENSION", field: "extension", formatter: skeletonFormatter, width: 120 },

            { title: "REGISTER", field: "register", formatter: skeletonFormatter, width: 120 },
            {
                title: "DISCOVERED ON",
                field: "date",
                formatter: skeletonFormatter,
                minWidth: 150,
            },
        ],
        layout: "fitColumns",
        resizableColumnFit: true,
        pagination: true,
        paginationSize: 100,
        paginationButtonCount: 5,
        paginationCounter: "rows",
    });

    fetch("/domains_json_all/")
        .then((response) => response.json())
        .then((data) => {
            console.log("triggered");
            var tableData = data;
            table.setData(tableData);
        })
        .then(() => {
            table.updateColumnDefinition("domain", { formatter: formatterDomainName });
            table.updateColumnDefinition("extension", { formatter: "plaintext" });
            table.updateColumnDefinition("register", { formatter: formatterRegisterBtn });
            table.updateColumnDefinition("date", { formatter: "datetime", formatterParams: { inputFormat: "iso", outputFormat: "MMM dd, yyyy HH:mm:ss", invalidPlaceholder: "(invalid date)" } });
        })
        .catch((error) => console.error("Error fetching data:", error));

    // Search functionality
    const input = document.getElementById("fSearch");
    input.addEventListener("keyup", function () {
        table.setFilter(matchAny, { value: input.value });
        if (input.value == " ") {
            table.clearFilter();
        }
    });

    function matchAny(data, filterParams) {
        //data - the data for the row being filtered
        //filterParams - params object passed to the filter
        //RegExp - modifier "i" - case insensitve

        var match = false;
        const regex = RegExp(filterParams.value, "i");

        for (var key in data) {
            if (regex.test(data[key]) == true) {
                match = true;
            }
        }
        return match;
    }
} else if (currentPage === "/domains/all/") {
    const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    const skeletonHeaderFormatter = function (cell, formatterParams) {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-400 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];

        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };

    const skeletonFormatter = function (cell, formatterParams) {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };

    const table = new Tabulator("#placeholder-table", {
        data: data,
        columns: [
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter, width: 400 },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter, width: 120 },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter },
            { title: "column", titleFormatter: skeletonHeaderFormatter, formatter: skeletonFormatter },
        ],
        layout: "fitColumns",
        resizableColumnFit: true,
    });
}
