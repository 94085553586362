document.addEventListener("DOMContentLoaded", function () {
    const currentPage = window.location.pathname;

    if (currentPage == "/") {

        const tagifyFields = Array(tagifyDomain, tagifyExt)

        tagifyFields.forEach((element) => {
            let clearInputField = element.DOM.input.parentElement.parentElement.nextElementSibling
            if (String(element.value) !== '') {
                clearInputField.style.cssText = 'opacity: 1;'
            } else {
                clearInputField.style.cssText = 'opacity: 0.3';
            }
            element.on('change', function(event) {
                if (event.detail.value !== '') {
                    clearInputField.style.cssText = 'opacity: 1;'
                } else {
                    clearInputField.style.cssText = 'opacity: 0.3;'
                    
                }
            })

            clearInputField.addEventListener('click', function() {
                element.removeAllTags()
                if (element === tagifyExt) {
                    const extFound = document.querySelectorAll('li.ext-active')
                        if (extFound) {
                            console.log('extFound', extFound)
                            extFound.forEach((element) => {
                                element.classList.remove('bg-gray-600/50', 'ext-active')
                            })
                            const headerSelect = document.querySelectorAll("div.extension-header div.select-all-ext div");
                            console.log('headerSelect', headerSelect)
                            headerSelect.forEach((element) => {
                                if (element.innerHTML == "deselect all") {
                                    // set 'select all' as button when we remove a tag, only if the current tag is 'deselect all'
                                    headerSelectButton(element, "select")
                                }
                            })
                            
                        }
                }
            })
        })


        function headerSelectButton(element, status) {
            /*
            Change innerHTML of 'select all' button
            element: clicked element
            status: 'select' or 'deselect'
            */
            const selectButton = element.closest(".extension-header").querySelector("h2 div.select-all-ext");
            if (status == "select") {
                selectButton.querySelector("div").innerHTML = "select all";
                selectButton.classList.add("bg-gray-200");
                selectButton.classList.remove("text-white", "bg-gray-600", "hover:bg-gray-700");
            }
            if (status == "deselect") {
                selectButton.querySelector("div").innerHTML = "deselect all";
                selectButton.classList.remove("bg-gray-200");
                selectButton.classList.add("text-white", "bg-gray-600", "hover:bg-gray-700");
            }
        }

        // Button below extension field. Click? Open extra extenions table
        const extraExtensionOptions = document.getElementById("extension-options");

        extraExtensionOptions.addEventListener("click", () => {
            const extraExtensionOptionsContainer = document.getElementById("options-accordion-collapse");
            extraExtensionOptionsContainer.classList.toggle("hidden");
        });


        // Button below extension field. Click? Open extra extenions table
        // const extraDomainOptions = document.getElementById("domain-options");

        // extraDomainOptions.addEventListener("click", () => {
        //     const extraDomainOptionsContainer = document.getElementById("domains-accordion-collapse");
        //     extraDomainOptionsContainer.classList.toggle("hidden");
        // });

        // Get all the extenion tags in the extension field
        const extensionFieldTags = tagifyExt.value
            .map((item) => item.value)
            .join(",")
            .split(",");

        // Select all extension tags in the extension table
        const allExtensionTags = document.querySelectorAll("li.ext");

        // Select all the 'select all' buttons in the extension table headers
        const ExtGroupSelectBtn = Array.from(document.getElementsByClassName("select-all-ext"));

        // For each of the tags in the extension table, do the following
        allExtensionTags.forEach((element) => {
            // Get the raw value
            const extValue = element.innerHTML;

            // If the extension field contains this particular tag, make the background tag green
            if (extensionFieldTags.includes(extValue)) {
                element.classList.add("bg-gray-600/50", "ext-active");
            }

            // Click on the table element tag, do the following
            element.addEventListener("click", function () {
                const selectAllBtn = element.closest("div.extension-header").querySelector(".select-all-ext");
                const extensionFieldTags = tagifyExt.value
                    .map((item) => item.value)
                    .join(",")
                    .split(",");

                // Is the tag already in the extension field? Remove the tag and remove the green bg
                if (extensionFieldTags.includes(extValue)) {
                    tagifyExt.removeTags(extValue);

                    // We removed the tag. Make sure the button has text 'select all'
                    if (selectAllBtn.querySelector("div").innerHTML == "deselect all") {
                        headerSelectButton(element, "select");
                    }
                } else {
                    // Not already in the extension field? Add tag
                    tagifyExt.addTags(extValue);

                    const allTagsSelectedLength = element.closest("ul").querySelectorAll("li.ext-active").length;
                    const originalArrayLength = element.closest("ul").querySelectorAll("li").length;

                    // Are all the tags tagged in this group? Change the button to 'deselect all'
                    if (allTagsSelectedLength == originalArrayLength) {
                        headerSelectButton(element, "deselect");
                    } else {
                        if (selectAllBtn.querySelector("div").innerHTML == "deselect all") {
                            headerSelectButton(element, "select");
                        }
                    }
                }
            });
        });

        // For each of the select buttons in the table headers, do this
        ExtGroupSelectBtn.forEach((element) => {
            // Get all the tags currently selected per group
            const allTagsSelected = Array.from(element.closest("h2").nextElementSibling.querySelectorAll("ul li.ext-active")).map((element) => element.textContent);

            // Get all the possible tags in the group
            const allTagsInGroup = Array.from(element.closest("h2").nextElementSibling.querySelectorAll("ul li")).map((element) => element.textContent);
            

            // Add nr of tags per group in the 'select all' button
            element.insertAdjacentHTML("beforeend", `<span> (${allTagsInGroup.length})</span>`);

            if (element.querySelector("div").innerHTML == "select all" && allTagsSelected.length === allTagsInGroup.length) {
                // Initialization. If all tags in the group are selected, change the 'select all' button to 'deselect all'
                headerSelectButton(element, "deselect");
            }

            // Click on the 'select all' button to add all extenions in this group
            element.addEventListener("click", (event) => {
                const allTagsInGroupNotActive = Array.from(element.closest("h2").nextElementSibling.querySelectorAll("ul li:not(.ext-active)")).map((element) => element.textContent); 
                // Make sure the header is not collapsing with stopPropagation()
                event.stopPropagation();

                if (element.querySelector("div").innerHTML == "select all") {
                    // Is the button called 'select all'? Add all the tags in this group to the tagifyExt extension field
                    tagifyExt.addTags(allTagsInGroupNotActive); // !TODO: deze werkt nu nog niet. Fixen met evt rij 148, allTagsInGroupNotActive
                    headerSelectButton(element, "deselect");
                } else {
                    // Button is 'deselect all'? Remove all the tags in this group from the tagifyExt extension field
                    headerSelectButton(element, "select");
                    tagifyExt.removeTags(allTagsInGroup);
                }
            });
        });

        tagifyExt.on("add", onInput);
        tagifyExt.on("remove", onDelete);

        function onInput(e) {
            // console.log("Added", e.detail.data.value);
            const extFound = Array.from(allExtensionTags).find((element) => element.textContent == e.detail.data.value);
            if (extFound) {
                extFound.classList.add("bg-gray-600/50", "ext-active");
                const originalArrayLength = extFound.closest("ul").querySelectorAll("li").length;
                const allTagsSelectedLength = extFound.closest("ul").querySelectorAll("li.ext-active").length;
                if (originalArrayLength == allTagsSelectedLength) {
                    const headerSelect = extFound.closest("div.extension-header").querySelector("div.select-all-ext div");
                    headerSelectButton(headerSelect, "deselect");
                }
            }
        }

        // const clearAllBtn = document.getElementById('ext-clear-all')
         
        // clearAllBtn.addEventListener('click', () => {
        //     const inputExtField = document.getElementById('inputExtensions')
        //     const inputDomainField = document.getElementById('inputDomains')
        //     inputExtField.value = ''
        //     inputDomainField.value = ''
        //     clearAllBtn.classList.add('opacity-20')
            
        // })
            
        

        function onDelete(e) {
            // console.log("Removed", e.detail.data.value);
            const extFound = Array.from(allExtensionTags).find((element) => element.textContent == e.detail.data.value);
            if (extFound) {
                extFound.classList.remove("bg-gray-600/50", "ext-active");
                const headerSelect = extFound.closest("div.extension-header").querySelector("div.select-all-ext div");
                if (headerSelect.innerHTML == "deselect all") {
                    // set 'select all' as button when we remove a tag, only if the current tag is 'deselect all'
                    headerSelectButton(headerSelect, "select");
                }
            }
        }
    }
});
