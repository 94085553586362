import { CountUp } from "countup.js";

window.onload = function () {
  const options = {
    duration: 3
  };
  fetch('/domains_all_nr/')
    .then(response => response.json())
    .then(data => {
      var countUp = new CountUp("countUp", data.result, options);
      countUp.start();
    })
    .catch(error => console.error('Error:', error));
};
