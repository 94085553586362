import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_semanticui.css";

let currentPage = window.location.pathname;

const formatterDomainName = function (cell, formatterParams) {
    const value = cell.getValue();
    let result = "<span class='font-medium'><a class='hover:underline' href='http://www." + value + "' title='Visit " + value + "' target='_blank'>" + value + "</a></span>";
    return result;
};

const formatterStatus = function (cell, formatterParams) {
    const value = cell.getValue();
    const row = cell.getRow(cell);
    const domainName = row.getData().domain;
    let result;
    if (value == 1) {
        result = `<div class="inline-block border rounded border-1 border-blue-500 text-blue-600 hover:text-white bg-blue-50 hover:bg-blue-500 hover:border-blue-700 hover:border-b-2 hover:-mb-px">
        <a href="
        https://www.kqzyfj.com/click-101096437-11774111?url=https%3A%2F%2Fwww.godaddy.com%2Fdomains%2Fsearchresults.aspx%3FdomainToCheck%3D${domainName}%26checkAvail%3D1" class="flex flex-row gap-2 items-center flex-grow px-2 py-0.5" target="_blank">
            register
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
          </svg>
        </a>
    </div>`;
    } else if (value == "loading") {
        result = `<div role="status">
        <svg aria-hidden="true" class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>`;
    } else if (value == 0) {
        result = "-";
    } else {
        result = "-";
    }
    return result;
};

const formatterSaved = function (cell, formatterParams) {
    const row = cell.getRow(cell);
    const status_value = row.getData().status;
    let result;
    if (status_value == 1) {
        result = `<a href="/domains" class="font-medium text-gray-600 hover:underline flex flex-row items-center gap-3">Saved <svg class="w-2 h-2 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
      </svg></a>`;
    } else {
        result = "";
    }
    return result;
};

const formatterRegisterBtn = function (cell, formatterParams) {
    const row = cell.getRow(cell);
    const domainName = row.getData().domain;
    const status_value = row.getData().status;
    let result;
    if (status_value == 1) {
        result = `<div class="inline-block border rounded border-1 border-blue-500 text-blue-600 hover:text-white bg-blue-50 hover:bg-blue-500 hover:border-blue-700 hover:border-b-2 hover:-mb-px">
                            <a href="
                            https://www.kqzyfj.com/click-101096437-11774111?url=https%3A%2F%2Fwww.godaddy.com%2Fdomains%2Fsearchresults.aspx%3FdomainToCheck%3D${domainName}%26checkAvail%3D1" class="flex flex-row gap-2 items-center flex-grow px-2 py-0.5" target="_blank">
                                register
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                              </svg>
                            </a>
                        </div>`;
    } else {
        result = "";
    }
    return result;
};

const competitionFormatter = function (cell) {
    let result = "";
    const value = cell.getValue();
    if (typeof value == "number") {
        if (1 < value < 3) {
            result = `${value} (low)`;
        } else if (3 >= value < 7) {
            result = `${value} (med)`;
        } else if (value >= 7) {
            result = `${value} (high)`;
        }
    }
    return result;
};

const skeletonFormatter = function (cell) {
    let result;
    const row = cell.getRow(cell);
    const status_value = row.getData().status;
    if (status_value == 0) {
        const skeletons = [`<a href="/pricing/" class="group w-full h-full flex items-center"><div class="animate-pulse h-2.5 bg-gray-200 group-hover:bg-blue-200 rounded-full dark:bg-gray-700 w-3/5"></div></a>`, `<a href="/pricing/" class="group w-full h-full flex items-center"><div class="animate-pulse h-2.5 bg-gray-200 group-hover:bg-blue-200 rounded-full dark:bg-gray-700 w-4/5"></div></a>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        result = skeletons[randomIndex];
    }
    return result;
};

const skeletonFormatterAlways = function (cell) {
    let result;
    const row = cell.getRow(cell);
    const skeletons = [`<a href="/pricing/" class="group w-full h-full flex items-center"><div class="animate-pulse h-2.5 bg-gray-200 group-hover:bg-blue-200 rounded-full dark:bg-gray-700 w-3/5"></div></a>`, `<a href="/pricing/" class="group w-full h-full flex items-center"><div class="animate-pulse h-2.5 bg-gray-200 group-hover:bg-blue-200 rounded-full dark:bg-gray-700 w-4/5"></div></a>`];
    const randomIndex = Math.floor(Math.random() * skeletons.length);
    result = skeletons[randomIndex];
    return result;
};

const formatTooltip = function (content) {
    return function (e, cell, onRendered) {
        const row = cell.getRow(cell);
        const status_value = row.getData().status;
        if (status_value == 0) {
            var el = document.createElement("div");
            el.style.cssText = "background: #000 !important; color: #fff !important;padding:0.5rem 1rem !important; border-radius: 5px !important;";
            el.innerText = content;
            return el;
        }
    };
};

const formatHeaderTooltip = function (content) {
    let content_test = content;
    var el = document.createElement("div");
    el.style.cssText = "background: #000 !important; color: #fff !important;padding:0.5rem 1rem !important; border-radius: 5px !important;";
    el.innerHTML = content_test;
    return el;
};

let cellTooltipMore = `Want to know more?`;
let headerTooltipKeywords = `Automatic keyword segmentation only in English.<br>Manually seperate keywords by capitalizing words:<br><strong>D</strong>omain<strong>B</strong>ulk<strong>S</strong>earch > domain bulk search`;
let headerTooltipHumbleworthAuction = `Value if sold on a well publicized auction, representing<br>immediate liquidity of a sale within weeks.<br><br>Powered by <a href="https://humbleworth.com/" title="HumbleWorth - Domain Valuation" target="_blank" class="font-bold">HumbeWorth.com</a>`;
let headerTooltipHumbleworthMarketplace = `Value for listing on marketplaces like Afternic or Dan, assuming<br>a long sale timeframe of years or decades.<br><br>Powered by <a href="https://humbleworth.com/" title="HumbleWorth - Domain Valuation" target="_blank" class="font-bold">HumbeWorth.com</a>`;
let headerTooltipHumbleworthBrokerage = `Premium value achievable with extensive marketing<br>and negotiation through brokerage. Targets maximum<br>return with highest effort and longest timeframe.<br><br>Powered by <a href="https://humbleworth.com/" title="HumbleWorth - Domain Valuation" target="_blank" class="font-bold">HumbeWorth.com</a>`;
let headerTooltipGoogleMonthlySearches = `Average monthly searches shows the average number of searches for this keyword and its closely related variations, <strong>worldwide in all languages</strong> on <strong>Google</strong>. Using this information, you can see how popular your keywords are at different times of the year.`;
let headerTooltipGoogleCompetition = `'Competition' shows the level of competition for the ad position for a keyword. The level of competition, low, medium, or high, is determined by the number of advertisers bidding on each keyword relative to all keywords on Google. If there is insufficient data available, you will see a dash (-).`;

if (currentPage === "/") {
    const data_json_fetch = JSON.parse(document.getElementById("domains_result_json").textContent);
    const domainsTotalFound = document.getElementById("domains-total-found");
    const domainsTotalSearched = document.getElementById("domains-total-searched");
    const domainsFoundDiv = document.getElementById("domains-found-div");

    const table = new Tabulator("#index-table", {
        rowFormatter: function (row) {
            const datarow = row.getData();
            if (datarow.status == 0) {
                row.getElement().style.color = "#999";
            }
        },
        printRowRange: function () {
            //only print rows where the age is over 18
            return this.getRows().filter((row) => {
                return row.getData().status == "-";
            });
        },
        index: "domain",
        placeholder: "no data available",
        initialSort: [{ column: "domain", dir: "asc" }],
        columns: [
            { title: "DOMAIN", field: "domain", formatter: formatterDomainName, minWidth: 200 },
            { title: "STATUS", field: "status", formatter: formatterStatus, width: 120 },
            { title: "EXTENSION", field: "ext", width: 120 },
            // { title: "REGISTER", field: "register", formatter: formatterRegisterBtn, width: 120 },
            {
                field: "saved",
                field: "saved",
                formatter: formatterSaved,
                width: 80,
                headerSort: false,
            },

            { title: `<div class="flex flex-row gap-2 items-center"><span>KEYWORDS</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "google_keywords", headerTooltip: formatHeaderTooltip(headerTooltipKeywords) },
            { title: `<div class="flex flex-row gap-2 items-center"><span>MONTHLY SEARCHES</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "google_searches", headerTooltip: formatHeaderTooltip(headerTooltipGoogleMonthlySearches) },
            { title: `<div class="flex flex-row gap-2 items-center"><span>COMPETITION</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "google_competition", formatter: competitionFormatter, headerTooltip: formatHeaderTooltip(headerTooltipGoogleCompetition) },
            // { title: "MORE", formatter: skeletonFormatterAlways, tooltip: formatTooltip(cellTooltipMore) },
            { title: `<div class="flex flex-row gap-2 items-center"><span>MARKETPLACE VALUE</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "humbleworth_marketplace", formatter: "money", formatterParams: { symbol: "$", precision: 0 }, headerTooltip: formatHeaderTooltip(headerTooltipHumbleworthMarketplace) },
            { title: `<div class="flex flex-row gap-2 items-center"><span>BROKERAGE VALUE</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "humbleworth_brokerage", formatter: "money", formatterParams: { symbol: "$", precision: 0 }, headerTooltip: formatHeaderTooltip(headerTooltipHumbleworthBrokerage) },
            { title: `<div class="flex flex-row gap-2 items-center"><span>AUCTION VALUE</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "humbleworth_auction", formatter: "money", formatterParams: { symbol: "$", precision: 0 }, headerTooltip: formatHeaderTooltip(headerTooltipHumbleworthAuction) },
            { title: "CREATED", field: "whois_created", formatter: "datetime", formatterParams: { outputFormat: "MMM dd, yyyy" }, minWidth: 120 },
            { title: "UPDATED", field: "whois_updated", formatter: "datetime", formatterParams: { outputFormat: "MMM dd, yyyy", invalidPlaceholder: "" }, minWidth: 120 },
            { title: "EXPIRES", formatter: skeletonFormatter, tooltip: formatTooltip(cellTooltipMore), minWidth: 150 },
        ],
        layout: "fitDataStretch",
        resizableColumnFit: true,
        pagination: true,
        paginationSize: 100,
        paginationButtonCount: 5,
    });

    if (data_json_fetch.length > 0) {
        console.log("lenght is", data_json_fetch.length);
        table.on("tableBuilt", function () {
            table.element.classList.remove("hidden");
            table.setData(data_json_fetch);
        });
    } else {
        table.element.classList.add("hidden");
    }

    async function fetchData() {
        try {
            const response = await fetch("/get_session_data/", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                console.log("Error occurred:", response.statusText);
                return null;
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        }
    }

    async function updateTable() {
        countUp += 1;
        console.log("Fetching data, attempt", countUp);
        const newData = await fetchData();
        if (countUp < 30) {
            // console.log("last count:", lastCount, "data length:", newData.data.length)
            if (countUp > 3) {
                isFetchInProgress = false;
            }
            if (newData.result_google === true && newData.result_whois === true && newData.result_humbleworth === true && newData.data.length === rowCount) {
                clearInterval(intervalId)
                console.log("All domains found.")
            }
            if (newData.data_updated === true) {
                console.log("DATA IS UPDATED!", "Google:", newData.result_google, ". Humbleworth:", newData.result_humbleworth, ". Whois:", newData.result_whois);
                table.updateOrAddData(newData.data).then(function () {
                    var rows = table.getRows();
                    rows.forEach(function (row) {
                        row.reformat();
                    });
                    if (newData.data.length === rowCount && newData.result_whois === true && newData.result_google === true && newData.result_humbleworth === true) {
                        console.log("Stopped checking for new data, because all the rows have been found. Took", countUp, "seconds.");
                        isFetchInProgress = false;
                        clearInterval(intervalId);
                    }
                });
            }
            if (newData.data.length > lastCount) {
                console.log("Domains found:", newData.data.length, "/", rowCount);
                domainsTotalFound.innerHTML = newData.data.length;
                lastCount = newData.data.length;
            }
        } else {
            console.log("Stopped checking for new data, because", countUp, "seconds have passed.");
            isFetchInProgress = false;
            clearInterval(intervalId);
        }
    }

    let intervalId;
    let countUp;
    let rowCount;
    let lastCount;

    document.getElementById("mainForm").addEventListener("submit", activateDomainSearch);

    let isFetchInProgress = false;
    function activateDomainSearch(event) {
        event.preventDefault();
        if (!isFetchInProgress) {
            const form = document.querySelector("form");

            const extensionsInput = form.querySelector('[name="extensions"]');
            const generateKeywordsInput = document.getElementById("options-domains-accordion-collapse").querySelector('[name="generate-keywords"]');
            const domainsInput = form.querySelector('[name="domains"]');

            if (extensionsInput.value && domainsInput.value) {
                isFetchInProgress = true;
                countUp = 0;
                domainsTotalFound.innerHTML = 0;
                lastCount = 0;
                domainsTotalSearched.innerHTML = "-";
                clearInterval(intervalId);
                intervalId = setInterval(updateTable, 1000);
                console.log("Starting domain search");
                fetch(form.action, {
                    method: "POST",
                    body: new FormData(form),
                })
                    .then((response) => {
                        if (!response.ok) {
                            console.log("error - response not OK");
                            table.element.classList.add("hidden");
                        }
                        if (generateKeywordsInput.checked) {
                            // first generate keywords, and based on new keywords trigger whois data
                            console.log("Generating keywords...");
                            fetch("/result/google", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ generate_keywords: true }),
                            }).then((response) => {
                                if (!response.ok) {
                                    console.log("error - response not OK");
                                }
                                fetch("/result/whois", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ generate_keywords: true }),
                                });
                                return response.json();
                            });
                        } else {
                            console.log("Fetching availability... ");
                            fetch("/result/available", {
                                method: "POST",
                            }).then((response) => {
                                if (!response.ok) {
                                    console.log("response not OK");
                                }
                                console.log("Fetching WHOIS, Google, HumbleWorth... ");
                                fetch("/result/whois", {
                                    method: "POST",
                                });
                                fetch("/result/google", {
                                    method: "POST",
                                });
                                fetch("/result/humbleworth", {
                                    method: "POST",
                                });
                            });
                        }
                        return response.json();
                    })
                    .then((data) => {
                        table.element.classList.remove("hidden");

                        if (data.length > 0) {
                            table.setData(data);
                            domainsFoundDiv.classList.remove("hidden");
                            console.log("Checking domains:", data.length);

                            rowCount = table.getDataCount();
                            domainsTotalSearched.innerHTML = rowCount;
                            if (rowCount >= 250) {
                                domainsFoundDiv.innerHTML += `<p class='inline'> (MAX) - see <a href="/pricing" target="_blank" class="underline text-blue-500">pricing</a> for unlimited combinations</p>`;
                            }
                        }
                    })

                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } else {
                console.log("No combinations given. Try again.");
            }
        } else {
            console.log("fetch still active, please wait..");
        }
    }
}
