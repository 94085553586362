import { TabulatorFull as Tabulator } from "tabulator-tables"; // see https://tabulator.info/docs/5.5/modules > filter, AjaxModule
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_semanticui.css";
window.DateTime = DateTime;
const currentPage = window.location.pathname;

if (currentPage === "/about/") {
    const skeletonFormatter = function (cell, formatterParams) {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };

    const data = [
        {
            domain: "amazon.com",
            extension: "com",
            admin_address: "P.O. Box 8102",
            whois_admin_city: "Reno",
            whois_admin_country: "US",
            whois_admin_email: "hostmaster@amazon.com",
            whois_admin_fax: "+1.2062667010",
            whois_admin_id: "",
            whois_admin_name: "Hostmaster, Amazon Legal Dept.",
            whois_admin_organization: "Amazon Technologies, Inc.",
            whois_admin_phone: "+1.2062664064",
            whois_admin_state: "NV",
            whois_admin_zipcode: "89507",
            whois_billing_address: "",
            whois_billing_city: "",
            whois_billing_country: "",
            whois_billing_email: "",
            whois_billing_fax: "",
            whois_billing_id: "",
            whois_billing_name: "",
            whois_billing_organization: "",
            whois_billing_phone: "",
            whois_billing_state: "",
            whois_billing_zipcode: "",
            whois_created: "Jan 1, 1994",
            whois_dnssec: "unsigned",
            whois_expires: "30 Oct, 2024",
            whois_name_servers: ["ns2.amzndns.net", "ns1.amzndns.co.uk", "ns2.amzndns.com", "ns2.amzndns.org", "ns1.amzndns.com", "ns1.amzndns.net", "ns1.amzndns.org", "ns2.amzndns.co.uk"],
            whois_registrant_address: "P.O. Box 8102",
            whois_registrant_city: "Reno",
            whois_registrant_country: "US",
            whois_registrant_email: "hostmaster@amazon.com",
            whois_registrant_name: "Hostmaster, Amazon Legal Dept.",
            whois_registrant_organization: "Amazon Technologies, Inc.",
            whois_registrant_state: "NV",
            whois_registrant_zipcode: "89507",
            whois_registrar: "MarkMonitor, Inc.",
            whois_registrar_abuse_email: "abusecomplaints@markmonitor.com",
            whois_registrar_abuse_phone: "+1.2086851750",
            whois_registrar_iana_id: "292",
            whois_registrar_url: "http://www.markmonitor.com",
            whois_status: "['clientUpdateProhibited ', '(https://www.icann.org/epp#clientUpdateProhibited)','clientTransferProhibited ', '(https://www.icann.org/epp#clientTransferProhibited)','clientDeleteProhibited ''(https://www.icann.org/epp#clientDeleteProhibited)', 'serverUpdateProhibited ', '(https://www.icann.org/epp#serverUpdateProhibited)','serverTransferProhibited ''(https://www.icann.org/epp#serverTransferProhibited)','serverDeleteProhibited ','(https://www.icann.org/epp#serverDeleteProhibited)']",
            whois_tech_address: "P.O. Box 8102",
            whois_tech_city: "Reno",
            whois_tech_country: "US",
            whois_tech_email: "hostmaster@amazon.com",
            whois_tech_fax: "+1.2062667010",
            whois_tech_id: "",
            whois_tech_name: "Hostmaster, Amazon Legal Dept.",
            whois_tech_organization: "Amazon Technologies, Inc.",
            whois_tech_phone: "+1.2062664064",
            whois_tech_state: "NV",
            whois_tech_zipcode: "89507",
            whois_updated: "May 16, 2023",
        },
        {
            domain: "linkedin.com",
            extension: "com",
            admin_address: "1000 W. Maude Ave",
            whois_admin_city: "Sunnyvale",
            whois_admin_country: "US",
            whois_admin_email: "hostmaster@linkedin.com",
            whois_admin_fax: "+1.6506870505",
            whois_admin_id: "",
            whois_admin_name: "Domain Administrator",
            whois_admin_organization: "LinkedIn Corporation",
            whois_admin_phone: "+1.6506873600",
            whois_admin_state: "CA",
            whois_admin_zipcode: "94085",
            whois_billing_address: "",
            whois_billing_city: "",
            whois_billing_country: "",
            whois_billing_email: "",
            whois_billing_fax: "",
            whois_billing_id: "",
            whois_billing_name: "",
            whois_billing_organization: "",
            whois_billing_phone: "",
            whois_billing_state: "",
            whois_billing_zipcode: "",
            whois_created: "Nov 2, 2002",
            whois_dnssec: "unsigned",
            whois_domain_name: "linkedin.com",
            whois_expires: "Nov 2, 2024",
            whois_name_servers: ["ns4-42.azure-dns.info", "dns3.p09.nsone.net", "dns4.p09.nsone.net", "ns3-42.azure-dns.org", "ns2-42.azure-dns.net", "dns1.p09.nsone.net", "ns1-42.azure-dns.com", "dns2.p09.nsone.net"],
            whois_registrant_address: "1000 W. Maude Ave",
            whois_registrant_city: "Sunnyvale",
            whois_registrant_country: "US",
            whois_registrant_email: "hostmaster@linkedin.com",
            whois_registrant_name: "Domain Administrator",
            whois_registrant_organization: "LinkedIn Corporation",
            whois_registrant_state: "CA",
            whois_registrant_zipcode: "94085",
            whois_registrar: "MarkMonitor, Inc.",
            whois_registrar_abuse_email: "abusecomplaints@markmonitor.com",
            whois_registrar_abuse_phone: "+1.2086851750",
            whois_registrar_iana_id: "292",
            whois_registrar_url: "http://www.markmonitor.com",
            whois_status: "['clientUpdateProhibited ','(https://www.icann.org/epp#clientUpdateProhibited)',,'clientTransferProhibited ','(https://www.icann.org/epp#clientTransferProhibited)',,'clientDeleteProhibited ','(https://www.icann.org/epp#clientDeleteProhibited)',,'serverUpdateProhibited ','(https://www.icann.org/epp#serverUpdateProhibited)',,'serverTransferProhibited ','(https://www.icann.org/epp#serverTransferProhibited)',,'serverDeleteProhibited ','(https://www.icann.org/epp#serverDeleteProhibited)']",
            whois_tech_address: "1000 W. Maude Ave",
            whois_tech_city: "Sunnyvale",
            whois_tech_country: "US",
            whois_tech_email: "hostmaster@linkedin.com",
            whois_tech_fax: "+1.6506870505",
            whois_tech_id: "",
            whois_tech_name: "Domain Administrator",
            whois_tech_organization: "LinkedIn Corporation",
            whois_tech_phone: "+1.6506873600",
            whois_tech_state: "CA",
            whois_tech_zipcode: "94085",
            whois_updated: "Nov 29, 2023",
        },
        {
            domain: "adobe.com",
            extension: "com",
            admin_address: "345 Park Avenue",
            whois_admin_city: "San Jose",
            whois_admin_country: "US",
            whois_admin_email: "dns-admin@adobe.com",
            whois_admin_fax: "",
            whois_admin_id: "",
            whois_admin_name: "Domain Administrator",
            whois_admin_organization: "Adobe Inc.",
            whois_admin_phone: "+1.4085366000",
            whois_admin_state: "California",
            whois_admin_zipcode: "95110",
            whois_billing_address: "",
            whois_billing_city: "",
            whois_billing_country: "",
            whois_billing_email: "",
            whois_billing_fax: "",
            whois_billing_id: "",
            whois_billing_name: "",
            whois_billing_organization: "",
            whois_billing_phone: "",
            whois_billing_state: "",
            whois_billing_zipcode: "",
            whois_created: "Nov 17, 1986",
            whois_dnssec: "Unsigned",
            whois_domain_name: "adobe.com",
            whois_expires: "May 17, 2024",
            whois_name_servers: ["a1-217.akam.net", "a10-64.akam.net", "a13-65.akam.net", "a26-66.akam.net", "a28-67.akam.net", "a7-64.akam.net"],
            whois_registrant_address: "345 Park Avenue",
            whois_registrant_city: "San Jose",
            whois_registrant_country: "US",
            whois_registrant_email: "dns-admin@adobe.com",
            whois_registrant_name: "Domain Administrator",
            whois_registrant_organization: "Adobe Inc.",
            whois_registrant_state: "California",
            whois_registrant_zipcode: "95110",
            whois_registrar: "NOM-IQ Ltd dba Com Laude",
            whois_registrar_abuse_email: "abuse@comlaude.com",
            whois_registrar_abuse_phone: "+44.2074218250",
            whois_registrar_iana_id: "470",
            whois_registrar_url: "https://www.comlaude.com",
            whois_status: "['clientDeleteProhibited ','https://www.icann.org/epp#clientDeleteProhibited','clientTransferProhibited ','https://www.icann.org/epp#clientTransferProhibited',,'clientUpdateProhibited ','https://www.icann.org/epp#clientUpdateProhibited','serverDeleteProhibited ','https://www.icann.org/epp#serverDeleteProhibited','serverTransferProhibited ','https://www.icann.org/epp#serverTransferProhibited']",
            whois_tech_address: "345 Park Avenue",
            whois_tech_city: "San Jose",
            whois_tech_country: "US",
            whois_tech_email: "dns-admin@adobe.com",
            whois_tech_fax: "",
            whois_tech_id: "",
            whois_tech_name: "Technical Manager",
            whois_tech_organization: "Adobe Inc.",
            whois_tech_phone: "+1.4085366000",
            whois_tech_state: "California",
            whois_tech_zipcode: "95110",
            whois_updated: "Sep 26, 2023",
        },
    ];

    const table_whois = new Tabulator("#whois-example-table", {
        rowFormatter: function (row) {
            const data = row.getData();
            if (data.status == 0) {
                row.getElement().style.color = "#999";
            }
        },
        data: data,
        columns: [
            {
                formatter: "rowSelection",
                titleFormatter: "rowSelection",
                hozAlign: "center",
                headerSort: false,
                width: 80,
                titleFormatterParams: {
                    rowRange: "active",
                },
                cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                },
            },
            { title: "DOMAIN", field: "domain", width: 150, maxWidth: 300 },
            { title: "EXTENSION", field: "extension" },
            { title: "CREATED", field: "whois_created", maxWidth: 200 },
            { title: "UPDATED", field: "whois_created", maxWidth: 200 },
            { title: "EXPIRES", field: "whois_created", maxWidth: 200 },
            { title: "REGISTRAR", field: "whois_registrar", maxWidth: 200 },
            { title: "REGISTRAR IANA ID", field: "whois_registrar_iana_id", maxWidth: 200 },
            { title: "REGISTRAR URL", field: "whois_registrar_url", maxWidth: 200 },
            { title: "REGISTRAR ABUSE MAIL", field: "whois_registrar_abuse_email", maxWidth: 200 },
            { title: "REGISTRAR ABUSE PHONE", field: "whois_registrar_abuse_phone", maxWidth: 200 },
            { title: "REGISTRANT NAME", field: "whois_registrant_name", maxWidth: 200 },
            { title: "REGISTRANT EMAIL", field: "whois_registrant_email", maxWidth: 200 },
            { title: "REGISTRANT ORG", field: "whois_registrant_org", maxWidth: 200 },
            { title: "REGISTRANT PHONE", field: "whois_registrant_phone", maxWidth: 200 },
            { title: "REGISTRANT COUNTRY", field: "whois_registrant_country", maxWidth: 200 },
            { title: "REGISTRANT STATE", field: "whois_registrant_state", maxWidth: 200 },
            { title: "REGISTRANT ZIP", field: "whois_registrant_zipcode", maxWidth: 200 },
            { title: "DNSSEC", field: "whois_dnssec", maxWidth: 200 },
            { title: "STATUS", field: "whois_status", maxWidth: 200 },
            { title: "NAME SERVERS", field: "whois_name_servers", maxWidth: 200 },
            { title: "ADMIN NAME", field: "whois_admin_name", maxWidth: 200 },
            { title: "ADMIN ID", field: "whois_admin_id", maxWidth: 200 },
            { title: "ADMIN ORG", field: "whois_admin_organization", maxWidth: 200 },
            { title: "ADMIN CITY", field: "whois_admin_city", maxWidth: 200 },
            { title: "ADMIN ADDRESS", field: "whois_admin_address", maxWidth: 200 },
            { title: "ADMIN STATE", field: "whois_admin_state", maxWidth: 200 },
            { title: "ADMIN ZIP", field: "whois_admin_zipcode", maxWidth: 200 },
            { title: "ADMIN COUNTRY", field: "whois_admin_country", maxWidth: 200 },
            { title: "ADMIN PHONE", field: "whois_admin_phone", maxWidth: 200 },
            { title: "ADMIN FAX", field: "whois_admin_fax", maxWidth: 200 },
            { title: "ADMIN EMAIL", field: "whois_admin_email", maxWidth: 200 },
            { title: "BILLING NAME", field: "whois_billing_name", maxWidth: 200 },
            { title: "BILLING ID", field: "whois_billing_id", maxWidth: 200 },
            { title: "BILLING ORG", field: "whois_billing_organization", maxWidth: 200 },
            { title: "BILLING CITY", field: "whois_billing_city", maxWidth: 200 },
            { title: "BILLING ADDRESS", field: "whois_billing_address", maxWidth: 200 },
            { title: "BILLING STATE", field: "whois_billing_state", maxWidth: 200 },
            { title: "BILLING ZIP", field: "whois_billing_zipcode", maxWidth: 200 },
            { title: "BILLING COUNTRY", field: "whois_billing_country", maxWidth: 200 },
            { title: "BILLING PHONE", field: "whois_billing_phone", maxWidth: 200 },
            { title: "BILLING FAX", field: "whois_billing_fax", maxWidth: 200 },
            { title: "BILLING EMAIL", field: "whois_billing_email", maxWidth: 200 },
            { title: "TECH NAME", field: "whois_tech_name", maxWidth: 200 },
            { title: "TECH ID", field: "whois_tech_id", maxWidth: 200 },
            { title: "TECH ORG", field: "whois_tech_organization", maxWidth: 200 },
            { title: "TECH CITY", field: "whois_tech_city", maxWidth: 200 },
            { title: "TECH ADDRESS", field: "whois_tech_address", maxWidth: 200 },
            { title: "TECH STATE", field: "whois_tech_state", maxWidth: 200 },
            { title: "TECH ZIP", field: "whois_tech_zipcode", maxWidth: 200 },
            { title: "TECH COUNTRY", field: "whois_tech_country", maxWidth: 200 },
            { title: "TECH PHONE", field: "whois_tech_phone", maxWidth: 200 },
            { title: "TECH FAX", field: "whois_tech_fax", maxWidth: 200 },
            { title: "TECH EMAIL", field: "whois_tech_email", maxWidth: 200 },
        ],
        layout: "fitDataFill",
        resizableColumnFit: true,
    });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const currentDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const exportFileName = `DomainBulkSearch.com - WHOIS export - ${currentDateString}`;

    //trigger download of data.csv file
    document.getElementById("download-whois-csv").addEventListener("click", function () {
        table_whois.download("csv", `${exportFileName}.csv`, {}, "active");
    });

    //trigger download of data.json file
    document.getElementById("download-whois-json").addEventListener("click", function () {
        // Call the function to fetch the current user's information
        table_whois.download("json", `${exportFileName}.json`, {}, "active");
    });

    //trigger download of data.xlsx file
    document.getElementById("download-whois-xlsx").addEventListener("click", function () {
        table_whois.download("xlsx", `${exportFileName}.xlsx`, { sheetName: "WHOIS" }, "active");
    });

    //trigger download of data.pdf file
    document.getElementById("download-whois-pdf").addEventListener("click", function () {
        table_whois.download(
            "pdf",
            `${exportFileName}.pdf`,
            {
                orientation: "portrait", //set page orientation to portrait
                title: "DomainBulkSearch.com - WHOIS export", //add title to report
            },
            "active"
        );
    });

    //trigger download of data.html file
    document.getElementById("download-whois-html").addEventListener("click", function () {
        table_whois.download("html", `${exportFileName}.html`, { style: true }, "active");
    });
}
