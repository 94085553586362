import { TabulatorFull as Tabulator } from "tabulator-tables"; // see https://tabulator.info/docs/5.5/modules > filter, AjaxModule
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_semanticui.css";
window.DateTime = DateTime;
const currentPage = window.location.pathname;

async function getData(url) {
    const response = await fetch(url);
    return response.json();
}

const currentUser = await getData("/account/get_current_user/");

if (currentPage === "/domains/" && totalDomainsFound > 0) {
    // Filter settings
    function updateFilters(fieldName, operator, fieldToFilter) {
        fieldToFilter.forEach(function (filterOption) {
            filterOption.addEventListener("change", function () {
                let filterValues = Array.from(fieldToFilter)
                    .filter((filterVal) => filterVal.checked)
                    .map((filterVal) => filterVal.value);
                if (filterValues) {
                    deleteFilter(fieldName);
                    const filterValuesFlat = filterValues.join(" ");
                    console.log(`filter '${fieldName}' with ${operator} ${filterValues}`);
                    table.addFilter(fieldName, operator, filterValuesFlat);
                }
            });
        });
    }

    function updateFilterResetButtons() {
        let filterResetButtons = document.querySelectorAll("[filter-reset]");
        filterResetButtons.forEach(function (resetButton) {
            resetButton.addEventListener("click", function () {
                const parentElement = this.closest("li").parentElement;
                const closestInput = parentElement.querySelector("input[name]");
                const inputNameGroup = closestInput.getAttribute("name");
                const sameInputGroup = document.querySelectorAll(`input[name='${inputNameGroup}']`);
                sameInputGroup.forEach(function (input) {
                    input.checked = false;
                });
                const fieldName = resetButton.getAttribute("filter-reset");
                if (closestInput) {
                    deleteFilter(fieldName);
                } else {
                    console.log(`Not able to reset filter ${fieldName}.`);
                }
            });
        });
    }

    function deleteFilter(fieldName) {
        const filters = table.getFilters();
        const filterExists = filters.find((dict) => dict["field"] === fieldName);
        if (filterExists) {
            console.log(`Reset filter ${fieldName}`);
            table.removeFilter(filterExists.field, filterExists.type, filterExists.value);
        }
    }

    let filterRatingButtons = document.querySelectorAll("input[name='filter-rating']");

    updateFilters("rating", ">=", filterRatingButtons);

    // Reset filters
    updateFilterResetButtons();

    // filter drop down menus: click on li triggers the checkbox or radio button
    function liClickTriggerCheckbox(queryToSelect) {
        const allFilters = document.querySelectorAll(queryToSelect);

        allFilters.forEach((element) => {
            // const filterLis = element.querySelectorAll('li');
            const filterLis = Array.from(element.querySelectorAll("li:not(.reset-button)"));
            filterLis.forEach((elementLi) => {
                const checkbox = elementLi.querySelector("input");
                elementLi.addEventListener("click", function (event) {
                    if (event.target !== checkbox) {
                        checkbox.checked = !checkbox.checked;
                        const changeEvent = new Event("change");
                        checkbox.dispatchEvent(changeEvent);
                    }
                });
            });
        });
    }

    liClickTriggerCheckbox("ul.filter");

    document.getElementById("filter-extension-dropdown-btn").addEventListener("click", function () {
        const ExtValues = table.getColumn("extension");
        const result = ExtValues.getCells();
        const uniques = [];
        const filterExtensionsDOM = document.getElementById("filter-extensions");
        if (filterExtensionsDOM.innerHTML.trim() == "") {
            filterExtensionsDOM.innerHTML = "";
            result.forEach((item) => {
                const value = item.getValue();
                if (!uniques.some((dict) => dict["ext"] === value)) {
                    uniques.push({ ext: value, count: 1 });
                } else {
                    const updateThis = uniques.find((dict) => dict["ext"] === value);
                    updateThis.count += 1;
                }
            });

            // Add reset button at top of ul
            const li = document.createElement("li");
            li.classList.add("reset-button");
            li.innerHTML = `<div class="flex items-center justify-center text-center rounded border-blue-200 border hover:border-blue-400 dark:hover:bg-gray-600 mb-2">
            <span filter-reset="extension" class="text-sm cursor-pointer block w-full h-full px-2 py-1 font-medium text-blue-600 dark:text-blue-500">Reset</span>
            </div>`;
            filterExtensionsDOM.appendChild(li);

            // Sort from highest to lowest
            uniques.sort((a, b) => b.count - a.count);
            // Create li for each item and append below reset button
            uniques.forEach((element) => {
                const li = document.createElement("li");
                li.innerHTML = `<div class="flex items-center p-2 gap-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <input type="checkbox" name="filter-extensions" value="${element.ext}" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <span class='font-bold'>${element.ext}</span> <span>(${element.count})</span>
                </div>`;

                filterExtensionsDOM.appendChild(li);
            });
            liClickTriggerCheckbox("ul#filter-extensions");
        }

        // After data is loaded, continue the filtering process
        const filterExtensionsButtons = document.querySelectorAll("input[name='filter-extensions']");

        updateFilters("extension", "keywords", filterExtensionsButtons);
        updateFilterResetButtons();
    });

    // Set rating for selected rows
    document.getElementById("set-rating-selected-rows").addEventListener("click", function () {
        const selectedData = table.getSelectedData();

        if (selectedData.length > 0) {
            const setRatingConfirmation = `<div class="relative p-4 w-full max-w-lg h-full md:h-auto">
            <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                <div class="mb-4 flex flex-col gap-3 text-gray-500 dark:text-gray-400">
                    <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Set the rating</h3>
                    <p>
                        Give the selected domains all the same rating.
                    </p>
                    <ul class="filter p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 flex flex-col items-center">              
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="0star" type="radio" name="set-rating" value="0" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="1star" type="radio" name="set-rating" value="1" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="2star" type="radio" name="set-rating" value="2" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="3star" type="radio" name="set-rating" value="3" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="4star" type="radio" name="set-rating" value="4" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#D2D2D2" stroke="#686868" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>

                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="5star" type="radio" name="set-rating" value="5" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg><svg width="14" height="14" viewBox="0 0 512 512" xml:space="preserve" style="padding: 0px 1px">
                                    <polygon fill="#FFEA00" stroke="#C1AB60" stroke-width="37.6152" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="259.216,29.942 330.27,173.919 489.16,197.007 374.185,309.08 401.33,467.31 259.216,392.612 117.104,467.31 144.25,309.08 29.274,197.007 188.165,173.919 "></polygon>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="items-center flex gap-3">
                    <button id="close-modal" type="button" class="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 w-full border-b-4 border-gray-700 hover:border-gray-500 rounded">Cancel</button>
                    <button id="confirm-button-set-rating" type="button" class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full">Confirm</button>
                </div>
            </div>
        </div>`;
            const modalEl = document.getElementById("info-popup");
            modalEl.innerHTML = setRatingConfirmation;
            const setRatingModal = new Modal(modalEl, {
                placement: "center",
            });

            setRatingModal.show();

            const closeModalEl = document.getElementById("close-modal");
            closeModalEl.addEventListener("click", function () {
                setRatingModal.hide();
            });

            document.getElementById("confirm-button-set-rating").addEventListener("click", function () {
                console.log(selectedData);
                const selectedValue = Number(document.querySelector('input[name="set-rating"]:checked').value);

                const updatePromises = selectedData.map((data) => {
                    const domain = data["domain"];
                    const updatedData = {
                        rating: selectedValue,
                    };
                    table.updateData([{ domain: domain, rating: selectedValue }]);
                    console.log(domain, "new value:", selectedValue);

                    return fetch(`/domains/update/${domain}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrfToken, // Include the CSRF token in the headers
                        },
                        body: JSON.stringify(updatedData),
                    }).then((response) => {
                        if (response.ok) {
                            return response.json(); // or return something else if needed
                        } else {
                            throw new Error("Failed to update entry");
                        }
                    });
                });

                Promise.all(updatePromises)
                    .then((results) => {
                        console.log("All entries updated successfully:", results);
                    })
                    .catch((error) => {
                        console.error("Error updating entries:", error);
                    });

                setRatingModal.hide();
                // location.reload(true);
            });
        }
    });

    // Delete rows
    document.getElementById("delete-selected-rows").addEventListener("click", function () {
        const selectedRows = table.getSelectedRows();
        const selectedData = table.getSelectedData();

        if (selectedData.length > 0) {
            const deleteConfirmation = `<div class="relative p-4 w-full max-w-lg h-full md:h-auto">
            <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                <div class="mb-4 flex flex-col gap-3 text-gray-500 dark:text-gray-400">
                    <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Are you sure?</h3>
                    <p>
                        Deleting these domain names will irreplacably delete them from your account.
                    </p>
                    <p>
                    Consider <a href="${urls.domainsearchPricing}" class="text-blue-600 font-medium">upgrading</a> to store more domains.
                    </p>
                </div>
                <div class="items-center flex gap-3">
                    <button id="close-modal" type="button" class="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 w-full border-b-4 border-gray-700 hover:border-gray-500 rounded">Cancel</button>
                    <button id="confirm-button" type="button" class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full">Confirm</button>
                </div>
            </div>
        </div>`;
            const modalEl = document.getElementById("info-popup");
            modalEl.innerHTML = deleteConfirmation;
            const deleteModal = new Modal(modalEl, {
                placement: "center",
            });

            deleteModal.show();

            const closeModalEl = document.getElementById("close-modal");
            closeModalEl.addEventListener("click", function () {
                deleteModal.hide();
            });

            document.getElementById("confirm-button").addEventListener("click", function () {
                console.log(selectedRows);
                table.deleteRow(selectedRows);
                console.log(selectedData);

                fetch(`/domains/delete/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrfToken, // Include the CSRF token in the headers
                    },
                    body: JSON.stringify(selectedData),
                })
                    .then((response) => {
                        if (response.ok) {
                            alert("Domains deleted successfully");
                        } else {
                            alert("Failed to delete domains");
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
                deleteModal.hide();
            });
        }
    });

    // Table settings
    const formatterDomainName = function (cell, formatterParams) {
        const row = cell.getRow(cell);
        const note_value = row.getData().note;
        const value = cell.getValue();

        let result = "<span class='font-medium'><a class='hover:underline' href='http://www." + value + "' title='Visit " + value + "' target='_blank'>" + value + "</a></span>";
        return result;
    };

    const formatterSavedOn = function (cell, formatterParams) {
        let result;
        if (userIsAuthenticated !== "false") {
            console.log("User is logged in.");
            result = { formatter: "datetime", formatterParams: { inputFormat: "iso", outputFormat: "MMM dd, yyyy HH:mm:ss", invalidPlaceholder: "(invalid date)" } };
        } else {
            console.log("Not logged in.");
            result = `Login to save`;
        }
        return result;
    };

    const formatterRegisterBtn = function (cell, formatterParams) {
        const row = cell.getRow(cell);
        const domainName = row.getData().domain;
        let result = `<div class="inline-block border rounded border-1 border-blue-500 text-blue-600 hover:text-white bg-blue-50 hover:bg-blue-500 hover:border-blue-700 hover:border-b-2 hover:-mb-px">
                            <a href="https://www.kqzyfj.com/click-101096437-11774111?url=https%3A%2F%2Fwww.godaddy.com%2Fdomains%2Fsearchresults.aspx%3FdomainToCheck%3D${domainName}%26checkAvail%3D1" class="flex flex-row gap-2 items-center flex-grow px-2 py-0.5" target="_blank">
                                register
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                              </svg>
                            </a>
                        </div>`;
        return result;
    };

    const formatterComment = function (cell, formatterParams) {
        const value = cell.getValue();
        let result;
        if (value) {
            result = `
            <svg class="w-3 h-3 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="text-gray-800" viewBox="0 0 14 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"/>
            </svg>`;
        } else {
            result = `
            <svg class="w-3 h-3 text-gray-300 hover:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"/>
            </svg>`;
        }
        return result;
    };

    const commentFnc = function (cell) {
        let value = cell.getValue() || "";
        const commentPopup = `<div class="relative p-4 w-full max-w-lg h-full md:h-auto">
            <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                <div class="mb-4 flex flex-col gap-3 text-gray-500 dark:text-gray-400">
                    <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Set a comment</h3>
                    <p>
                    <textarea id="comment-message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here...">${value}</textarea>
                    </p>         
                </div>
                <div class="items-center flex gap-3">
                    <button id="close-modal" type="button" class="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 w-full border-b-4 border-gray-700 hover:border-gray-500 rounded">Cancel</button>
                    <button id="confirm-button-set-comment" type="button" class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full">Confirm</button>
                </div>
            </div>
        </div>`;

        const modalEl = document.getElementById("info-popup");
        modalEl.innerHTML = commentPopup;
        const commentModal = new Modal(modalEl, {
            placement: "center",
        });

        const closeModalEl = document.getElementById("close-modal");
        closeModalEl.addEventListener("click", function () {
            commentModal.hide();
        });

        commentModal.show();

        document.getElementById("confirm-button-set-comment").addEventListener("click", function () {
            const commentValue = { comment: document.querySelector('textarea[id="comment-message"]').value };

            const domain = cell.getRow(cell).getData().domain;
            fetch(`/domains/update/${domain}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken, // Include the CSRF token in the headers
                },
                body: JSON.stringify(commentValue),
            })
                .then((response) => {
                    if (response.ok) {
                        console.log("commentValue:", commentValue);
                        table.updateData([{ domain: domain, note: commentValue["comment"] }]);
                        console.log("response OK and row updated");
                        return response.json(); // or return something else if needed
                    } else {
                        throw new Error("Failed to update entry");
                    }
                })
                .then(function () {
                    let row = cell.getRow(cell);
                    console.log("reformating row:", row);
                    row.reformat();
                });
            commentModal.hide();
        });
    };

    const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    const skeletonFormatter = function () {
        const skeletons = [`<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>`, `<div class="animate-pulse h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>`];
        const randomIndex = Math.floor(Math.random() * skeletons.length);
        const result = skeletons[randomIndex];
        return result;
    };
    const formatHeaderTooltip = function (content) {
        let content_test = content;
        var el = document.createElement("div");
        el.style.cssText = "background: #000 !important; color: #fff !important;padding:0.5rem 1rem !important; border-radius: 5px !important;";
        el.innerHTML = content_test;
        return el;
    };
    const formatTooltip = function (content) {
        return function (e, cell, onRendered) {
            const row = cell.getRow(cell);
            const status_value = row.getData().status;
            if (status_value == 0) {
                var el = document.createElement("div");
                el.style.cssText = "background: #000 !important; color: #fff !important;padding:0.5rem 1rem !important; border-radius: 5px !important;";
                el.innerText = content;
                return el;
            }
        };
    };
    
    let cellTooltipMore = `Want to know more?`

    let headerTooltipKeywords = `Automatic keyword segmentation only in English.<br>Manually seperate keywords by capitalizing words:<br><strong>D</strong>omain<strong>B</strong>ulk<strong>S</strong>earch > domain bulk search`;

    const table = new Tabulator("#all-domains-table", {
        rowFormatter: function (row) {
            const data = row.getData();
            if (data.status == 0) {
                row.getElement().style.color = "#999";
            }
        },
        data: data,
        index: "domain",
        placeholder: `<div style="">No data found</div>`,
        // initialSort: [{ column: "date", dir: "desc" }], // Doesn't work at the moment with filtering
        columns: [
            {
                index: "domain",
                formatter: "rowSelection",
                titleFormatter: "rowSelection",
                hozAlign: "center",
                headerSort: false,
                width: 80,
                titleFormatterParams: {
                    rowRange: "active",
                },
                cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                },
            },
            { title: "DOMAIN", field: "domain", minWidth: 150, formatter: skeletonFormatter },
            { title: "EXTENSION", field: "extension", formatter: skeletonFormatter },
            {
                title: "RATING",
                field: "rating",
                
                formatter: skeletonFormatter,
                validator: "integer",
                hozAlign: "center",
                cellEdited: function (cell) {
                    const updatedData = {
                        rating: cell.getValue(),
                    };
                    const domain = cell.getRow(cell).getData().domain;
                    fetch(`/domains/update/${domain}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrfToken, // Include the CSRF token in the headers
                        },
                        body: JSON.stringify(updatedData),
                    })
                        .then((response) => {
                            if (response.ok) {
                                console.log("Entry updated successfully");
                            } else {
                                console.error("Failed to update entry");
                            }
                        })

                        .catch((error) => {
                            console.error("Error:", error);
                        });
                },
            },
            { title: "REGISTER", field: "register", formatter: skeletonFormatter },
            {
                title: `<svg class="w-3 h-3 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="text-gray-800" viewBox="0 0 14 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"/>
          </svg>`,
                field: "note",
                formatter: skeletonFormatter,
                
                tooltip: function (e, cell, onRendered) {
                    var el = document.createElement("div");
                    el.classList.add("custom-tooltip");
                    var commentValue = cell.getValue() ? `<strong>Comment:</strong><br>` + cell.getValue() : "add comment";
                    el.innerHTML = commentValue; //return cells "field - value";

                    return el;
                },
                cellClick: function (e, cell) {
                    commentFnc(cell);
                },
            },
            {
                title: "SAVED ON",
                field: "date",
                formatter: skeletonFormatter,
            },
            { title: `<div class="flex flex-row gap-2 items-center"><span>KEYWORDS</span><svg width="16px" height="16px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#aaaaaa" stroke-width="0.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z" fill="#aaaaaa"></path> <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="#aaaaaa"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#aaaaaa"></path> </g></svg></div>`, field: "google_keywords", headerTooltip: formatHeaderTooltip(headerTooltipKeywords) },
            { title: "MONTHLY SEARCHES", field: "google_searches" },
            { title: "COMPETITION", field: "google_competition" },
            { title: "MORE", formatter: skeletonFormatter, tooltip: formatTooltip(cellTooltipMore) },
        ],
        layout: "fitDataFill",
        resizableColumnFit: true,
        pagination: true,
        paginationSize: 100,
        paginationButtonCount: 5,
        paginationCounter: "rows",
    });

    fetch("/domains_json/")
    
        .then((response) => response.json())
        .then((data) => {
            console.log('triggered')
            var tableData = data.queryset;
            table.setData(tableData);
        })
        .then(() => {
            table.updateColumnDefinition("domain", { formatter: formatterDomainName });
            table.updateColumnDefinition("extension", { formatter: "plaintext" });
            table.updateColumnDefinition("rating", { formatter: "star", editor: true });
            table.updateColumnDefinition("register", { formatter: formatterRegisterBtn });
            table.updateColumnDefinition("note", { formatter: formatterComment });
            table.updateColumnDefinition("date", { formatter: "datetime", formatterParams: { inputFormat: "iso", outputFormat: "MMM dd, yyyy HH:mm:ss", invalidPlaceholder: "(invalid date)" } });
            table.updateColumnDefinition("google_keywords", { formatter: "plaintext" });
            table.updateColumnDefinition("google_searches", { formatter: "plaintext" });
            table.updateColumnDefinition("google_competition", { formatter: "plaintext" });
        })
        .catch((error) => console.error("Error fetching data:", error));

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const currentDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const exportFileName = `DomainBulkSearch - export - ${currentDateString}`;

    function triggerUpgradeModal() {
        console.log("You need to upgrade");
        const modalEl = document.getElementById("info-popup");
        const setUpgradeHTML = `
        <div class="relative p-4 w-full max-w-lg h-full md:h-auto">
            <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                <div class="mb-2 flex flex-col gap-3 text-gray-500 dark:text-gray-400">
                    <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">You need to upgrade</h3>
                    <p class="mb-2">
                        You can only use this functionality in an upgraded plan. Please see our plans to use this functionality.
                    </p>
                    <a href="${urls.domainsearchPricing}" class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full">See our upgrade plans</a>
                </div>
                <div class="items-center flex gap-3">
                    <button id="close-modal" type="button" class="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 w-full border-b-4 border-gray-700 hover:border-gray-500 rounded">Cancel</button>
                    
                </div>
            </div>
        </div>
        `;

        modalEl.innerHTML = setUpgradeHTML;
        const setUpgradeModal = new Modal(modalEl, {
            placement: "center",
        });

        setUpgradeModal.show();

        const closeModalEl = document.getElementById("close-modal");
        closeModalEl.addEventListener("click", function () {
            setUpgradeModal.hide();
        });
    }

    //trigger download of data.csv file
    document.getElementById("download-csv").addEventListener("click", function () {
        if (currentUser.vip_status >= 1) {
            let downloadRange;
            if (this.classList.contains("export-selected")) {
                downloadRange = "selected";
            } else {
                downloadRange = "active";
            }
            table.download("csv", `${exportFileName}.csv`, {}, downloadRange);
        } else {
            triggerUpgradeModal();
        }
    });

    //trigger download of data.json file
    document.getElementById("download-json").addEventListener("click", function () {
        // Call the function to fetch the current user's information
        if (currentUser.vip_status >= 2) {
            table.download("json", `${exportFileName}.json`, {}, "active");
        } else {
            triggerUpgradeModal();
        }
    });

    //trigger download of data.xlsx file
    document.getElementById("download-xlsx").addEventListener("click", function () {
        if (currentUser.vip_status >= 1) {
            table.download("xlsx", `${exportFileName}.xlsx`, { sheetName: "My Data" }, "active");
        } else {
            triggerUpgradeModal();
        }
    });

    //trigger download of data.pdf file
    document.getElementById("download-pdf").addEventListener("click", function () {
        if (currentUser.vip_status >= 3) {
            table.download(
                "pdf",
                `${exportFileName}.pdf`,
                {
                    orientation: "portrait", //set page orientation to portrait
                    title: "DomainBulkSearch - export", //add title to report
                },
                "active"
            );
        } else {
            triggerUpgradeModal();
        }
    });

    //trigger download of data.html file
    document.getElementById("download-html").addEventListener("click", function () {
        if (currentUser.vip_status >= 3) {
            table.download("html", `${exportFileName}.html`, { style: true }, "active");
        } else {
            triggerUpgradeModal();
        }
    });

    // Search functionality
    const input = document.getElementById("fSearch");
    input.addEventListener("keyup", function () {
        table.setFilter(matchAny, { value: input.value });
        if (input.value == " ") {
            table.clearFilter();
        }
    });

    function matchAny(data, filterParams) {
        //data - the data for the row being filtered
        //filterParams - params object passed to the filter
        //RegExp - modifier "i" - case insensitve

        var match = false;
        const regex = RegExp(filterParams.value, "i");

        for (var key in data) {
            if (regex.test(data[key]) == true) {
                match = true;
            }
        }
        return match;
    }
}
