import Cropper from "cropperjs";
// import Cropper from "cropperjs/dist/cropper.min.js";
import "cropperjs/dist/cropper.css";

const alertBox = document.getElementById("alert-box");
const avatarBox = document.getElementById("avatar-box");
const avatarForm = document.getElementById("avatar-form");
const confirmBtn = document.getElementById("avatar-confirm-btn");
const inputField = document.getElementById("avatar_uploaded");
const avatarModal = document.getElementById("avatar-modal");
const cropperZoomIn = document.getElementById("cropper-zoom-in");
const cropperZoomOut = document.getElementById("cropper-zoom-out");
const cropperStepsRange = document.getElementById("steps-range");
const avatarModalCloseBtn = document.querySelector('[data-modal-hide="avatar-modal"]');

const csrf = document.getElementsByName("csrfmiddlewaretoken");

if (inputField !== null) {
  inputField.addEventListener("change", () => {
    alertBox.innerHTML = "";
    avatarModal.classList.remove("hidden");
    const img_file = inputField.files[0];
    const url = URL.createObjectURL(img_file);
    avatarBox.innerHTML = `<img src="${url}" id="avatar-img" class="max-w-full block" />`;
    const avatarImg = document.getElementById("avatar-img");

    var cropper = new Cropper(avatarImg, {
      viewMode: 1,
      background: false,
    //   movable: false,
      guides: false,
      center: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      autoCropArea: 0.8,
      dragMode: 'move',
      aspectRatio: 1 / 1,
      zoomOnWheel: false,
      crop(event) {
        console.log(event.detail.x);
        console.log(event.detail.y);
        console.log(event.detail.width);
        console.log(event.detail.height);
        console.log(event.detail.rotate);
        console.log(event.detail.scaleX);
        console.log(event.detail.scaleY);
      },
    });

    cropperZoomIn.addEventListener("click", () => {
      let currentValue = parseFloat(cropperStepsRange.value);
      currentValue += 0.1;
      cropper.zoom(0.1);
      cropperStepsRange.value = currentValue.toFixed(1); // Limit the decimal places to 1
    });

    cropperZoomOut.addEventListener("click", () => {
      let currentValue = parseFloat(cropperStepsRange.value);
      currentValue -= 0.1;
      cropper.zoom(-0.1);
      cropperStepsRange.value = currentValue.toFixed(1); // Limit the decimal places to 1
    });


    avatarModalCloseBtn.addEventListener('click', () => {
        inputField.value = ""
    });
    

    confirmBtn.addEventListener("click", () => {
      cropper
        .getCroppedCanvas({
          width: 350,
          height: 350,
        })
        .toBlob((blob) => {
          console.log("confirmed");
          const fd = new FormData();
          fd.append("csrfmiddlewaretoken", csrf[0].value);
          fd.append("avatar_uploaded", blob, "my_image.png");

          // Configure the fetch request for a POST method
          fetch(avatarForm.action, {
            method: "POST",
            body: fd,
            enctype: "multipart/form-data",
            cache: "no-cache",
          })
            .then(function (response) {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text(); // or response.json() if expecting JSON data
            })
            .then(function (data) {
              // The request is complete, and the response is successful
              console.log("success", data);
              window.location = "/account/dashboard";
              alertBox.innerHTML = "Successfully cropped and saved the image";
              avatarBox.innerHTML = "";
            })
            .catch(function (error) {
              // Handle errors here
              console.log("error", error);
              alertBox.innerHTML = "OOPS. Something went wrong.";
            });
        });
    });
  });
} else {
}
